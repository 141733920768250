@import 'src/styles/modules';

.body {
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include mq(md) {
    flex-direction: column;
  }
}

.item {
  border: 1px solid #ebf0f4;
  padding: 26px 32px;
}

.itemWrap {
  display: flex;

  @include mq(md) {
    flex-direction: column;
    width: 100%;
  }
}

.icon {
  height: auto;
  margin-right: 8px;
  width: 22px;
  path {
    fill: $primary;
  }
}

.arrow {
  height: auto;
  width: 10px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.count {
  font-family: Helvetica, sans-serif;
  font-size: 40px;
  font-weight: bold;
}

.unit {
  font-size: 20px;
  font-weight: bold;
}

.button {
  margin-right: 24px;

  @include mq(md) {
    display: none;
  }
}
