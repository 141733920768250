@import 'src/styles/colors';

.Navigation {
  background: $black;
  border-right: 1px solid #e7ecf1;
  height: calc(100vh - 58px);
  left: 0;
  position: fixed;
  top: 58px;
  width: 212px;
}

.NavigationInner {
  padding-top: 12px;
  position: relative;
}

.listItem {
  color: $white;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 16px 20px 16px 12px;

  &:hover {
    background-color: $light-gray;
    .listIconArrow {
      margin-right: -2px;
      path {
        fill: $white;
      }
    }
  }
  &[data-active='true'] {
    background-color: $light-black;
    .listIcon {
      path {
        fill: $primary;
      }
    }
    .listIconArrow {
      margin-right: -4px;
      path {
        fill: $white;
      }
    }
  }
}

.listText {
  font-size: 14px;
}

.listIcon {
  height: auto;
  margin-right: 12px;
  width: 24px;
}

.listIconArrow {
  height: auto;
  transition: 0.3s;
  width: 7px;
  path {
    fill: #969fbe;
  }
}

.listBottom {
  border-top: 1px solid #ebf0f4;
  bottom: 0;
  position: absolute;
  width: 100%;
}
