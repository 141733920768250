@import 'src/styles/modules';

.header {
  align-items: center;
  background: $white;
  border-bottom: 1px solid #e7ecf1;
  display: flex;
  height: 58px;
  justify-content: space-between;
  left: 0;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.content {
  padding: 48px 24px;

  @include mq(md) {
    padding: 48px 8px;
  }
}

.footer {
  bottom: 0;
  display: flex;
  gap: 16px;
  padding: 24px;
  position: absolute;
  right: 0;
}
