@import 'src/styles/colors';
@import 'src/styles/modules';

.wrap {
  background: #fff;
  border: 1px solid #ebf0f4;
  border-radius: 6px;
  display: block;
  width: 100%;
}

.head {
  border-bottom: 1px solid #f0f1f3;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @include mq(md) {
    padding: 16px 20px;
  }
}

.body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 20px 20px;
  width: 100%;
  @include mq(md) {
    flex-direction: column;
  }
}

.item {
  background: #f6f8fa;
  border: 1px solid #ebf0f4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-right: 12px;
  margin-top: 16px;
  padding: 12px 16px;
  width: calc(33.3% - 12px);
  @include mq(md) {
    width: 100%;
  }
}

.date {
  color: #969fbe;
  font-size: 10px;
}

.name {
  font-size: 16px;
  font-weight: bold;
  margin-right: 4px;
}

.term {
  font-size: 12px;
  margin-bottom: 4px;
}

.bottom {
  font-size: 12px;
  margin-top: 4px;
}
