@import '/src/styles/variables';
.toast {
  border-radius: 4px;
  padding: 16px 20px;
  @include mq(md) {
    padding: 12px;
    .text {
      font-size: 14px;
    }
    .icon {
      width: 16px;
    }
  }
}

.success {
  background: #5dca88;
  color: #fff;
  .icon {
    path {
      fill: #fff;
    }
  }
  .text {
    color: #fff;
  }
}
.error {
  background: #fff4f8;
  .icon {
    path {
      fill: #ff0b5e;
    }
  }
  .text {
    color: #ff0b5e;
  }
}
.icon {
  height: auto;
  width: 20px;
  path {
    fill: #fff;
  }
}
.text {
  font-size: 16px;
  font-weight: bold;
}

.info {
  background: #e3ebff;
  color: #043bc7;
  .icon {
    path {
      fill: #043bc7;
    }
  }
  .text {
    color: #043bc7;
  }
}
