@import 'src/styles/colors';
@import '/src/styles/variables';

.header {
  align-items: center;
  background: $white;
  border-bottom: 1px solid #e7ecf1;
  display: flex;
  height: 58px;
  justify-content: space-between;
  left: 0;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.button {
  @include mq(md) {
    font-size: 10px;
    padding: 12px 8px !important;
  }
}
