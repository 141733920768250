@import '/src/styles/variables';
.main {
  margin-inline-start: 0 !important;
  width: calc(100% - 212px);

  @include mq(md) {
    min-width: 100%;
    width: 100%;
  }
}

.footer {
  bottom: 0;
  display: flex;
  gap: 16px;
  padding: 24px;
  position: absolute;
  right: 0;
  @include mq(md) {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px 12px;
    position: static;
  }
}
