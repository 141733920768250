@import 'src/styles/colors';

.button {
  &[data-color='normal'] {
    background: $shade;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(40, 44, 57, 0.2),
          rgba(40, 44, 57, 0.2)
        ),
        $shade;
    }
  }

  &[data-color='primary'] {
    background-color: $primary;
    color: $white;
    transition: filter 0.2s;
    &:hover {
      background-color: $primary;
      filter: brightness(1.2);
    }
  }

  &[data-color='secondary'] {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      $secondary;
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: $secondary;
      filter: brightness(1.2);
    }
  }

  &[data-color='tertiary'] {
    background-color: $white;
    border: 1px solid $light-purple;
    color: $light-purple;
    transition: filter 0.2s;
    &:hover {
      background-color: $white !important;
      filter: brightness(1.2);
    }
  }

  &[data-color='gray'] {
    background-color: $light-purple;

    color: $white;
    transition: filter 0.2s;
    &:hover {
      background-color: $light-purple !important;
      filter: brightness(1.2);
    }
  }

  &[data-color='warning'] {
    background-color: $warning;
    color: $white;
  }

  &[data-color='disabled'] {
    background-color: $shade2;
    color: $white;
  }

  &[data-color='redGraduation'] {
    background: $red-gradation;
    color: $white;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2)
        ),
        linear-gradient(82.62deg, #ff0259 0%, #ff0058 52.08%, #ff7337 100%);
    }
  }

  &[data-size='medium'] {
    font-size: 14px;
    height: 36px;
  }

  &[data-size='large'] {
    font-size: 14px;
    height: 42px;
  }

  &[data-size='small'] {
    font-size: 14px;
    height: 24px;
  }
}
