@import 'src/styles/modules';

.informationBox {
  align-items: center;
  background: #f2f4ff;
  border: 1px solid #8fa1ff;
  border-radius: 4px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  padding: 16px 24px;

  @include mq(md) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.icon {
  height: auto;
  margin-right: 8px;
  width: 24px;
}

.buttonBox {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.linkButton {
  display: block;
  @include mq(md) {
    display: none;
  }
}
