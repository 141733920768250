@import 'src/styles/colors';
@import '/src/styles/variables';

.items {
  display: flex;
  justify-content: space-between;
  @include mq(md) {
    flex-direction: column;
  }
}
.item {
  background: #f6f8fa;
  border: 1px solid #ebf0f4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 26px 24px 8px;
  width: calc(100% / 3 - 18px);
  @include mq(md) {
    margin-bottom: 20px;
    padding: 20px 20px 8px;
    width: 100%;
  }
}

.itemInner {
  margin-bottom: 4px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  @include mq(md) {
    display: inline-block;
    font-size: 16px;
  }
}

.description {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
  margin-bottom: 10px;
  @include mq(md) {
    font-size: 12px;
  }
}

.subtitle {
  background: #fff4f8;
  border: 1px solid $primary;
  border-radius: 4px;
  color: $primary;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 2px 4px;
  @include mq(md) {
    display: inline-block;
    margin-right: 12px;
  }
}

.linkgroup {
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.link {
  border-bottom: 1px solid $primary;
  color: $primary;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-inline-start: 0 !important;
  margin-right: 8px;
  padding-bottom: -2px;
  transition: 0.3s;
  &:hover {
    border-color: transparent;
    filter: opacity(0.8);
  }
}

.image {
  height: auto;
  max-width: inherit;
  width: 100%;
}

.icon {
  height: auto;
  margin-right: 2px;
  width: 14px;
  path {
    fill: $primary;
  }
}
