@import 'src/styles/colors';
@import '/src/styles/variables';

.wrap {
  padding: 20px 24px;
}

.body {
  background: #fff;
  border-bottom: 1px solid #ebf0f4;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: 1px solid #ebf0f4;
  border-right: 1px solid #ebf0f4;
  margin-bottom: 20px;
  margin-top: 0 !important;
  padding: 20px 24px;
  &Border {
    @extend .body;
    border: 2px solid #282c39;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 0;
    padding-top: 12px;
  }
  @include mq(md) {
    padding: 16px 20px;
  }
}

.head {
  background: #fff;
  border-bottom: 1px solid #e7ecf1;
  border-left: 1px solid #ebf0f4;
  border-right: 1px solid #ebf0f4;
  border-top: 1px solid #ebf0f4;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-top: 16px;
  padding: 20px 24px;
  &Border {
    @extend .head;
    border: 2px solid #282c39;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-bottom: 0;
  }
  &Inner {
    @extend .head;
    margin-top: 0;
  }
  @include mq(md) {
    padding: 20px;
  }
}

.twoColumn {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include mq(md) {
    flex-direction: column-reverse;
    padding: 16px 20px;
  }
}

.mainColumn {
  width: calc(72% - 24px);
  width: 100%;
  @include mq(md) {
    width: 100%;
  }
}
.subColumn {
  padding: 20px;
  width: 28%;
  @include mq(md) {
    width: 100%;
  }
}
